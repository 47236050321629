@import '../navbar/navbar.css';
@import '../header/header.css';
@import '../service/service.css';
@import '../footer/footer.css';
@import '../logo/logo.css';
@import '../partnership/partnership.css';
@import '../allServices/allServices.css';
@import '../about/about.css';
@import '../whyUs/whyUs.css';
@import '../scrollBtn/scrollBtn.css';
@import '../notFound/not-found.css';
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900");
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 17px 34px;
  color: #c8c8c8;
  background-color: transparent;
  border: 2px solid #c8c8c8;
  border-radius: 0.25rem;
  width: 240px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.2s;
  cursor: pointer;
}
.btn:hover {
  background: white;
  border: 2px solid #202020;
  color: #202020;
  box-shadow: none;
}
.btn.light {
  border: 2px solid #202020;
  color: #202020;
}
.btn.light:hover {
  background: #202020;
  color: #c8c8c8;
  box-shadow: none;
  border: 2px solid #c8c8c8;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans";
}

body {
  overflow-x: hidden;
}

.App {
  background: #202020;
  color: #c8c8c8;
  min-height: 100vh;
  max-width: 100%;
}
.App a {
  text-decoration: none;
}
.App.light {
  background: #f5f5f5;
  color: #202020;
}/*# sourceMappingURL=main.css.map */