@import '../variables/variables.scss';
@import '../navbar/navbar.css';
@import '../header/header.css';
@import '../service/service.css'; 
@import '../footer/footer.css';
@import '../logo/logo.css';
@import '../partnership/partnership.css'; 
@import '../allServices/allServices.css';
@import '../about/about.css';
@import '../whyUs/whyUs.css';
@import '../scrollBtn/scrollBtn.css';
@import '../notFound/not-found.css';
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900');

html {
    scroll-behavior: smooth;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Josefin Sans';
}
body{
    overflow-x: hidden;
}
.App{
    background: $bg;
    color: $white;
    min-height: 100vh;
    max-width: 100%;
    a{
        text-decoration: none;
    }
    &.light {
        background: $light-bg;
        color: $bg;
    }
}
